<template>
  <div class="article-page">
    <transition name="fade" mode="out-in">
      <div class="article-store">
        <h1>{{ listCurArticle.name }}</h1>
        <div
          :key="listCurArticle.id"
          class="seo-content article-content"
          v-html="listCurArticle.description"
        ></div>
        <router-link
          class="btn btn-blue btn-big"
          v-if="listCurArticleCountry"
          :to="'/country/' + listCurArticleCountry.link"
          ><span
            v-html="
              listCurArticleCountry.title
                ? translations('SearchCompaniesCountry', [
                    listCurArticleCountry.title,
                  ])
                : translations('SearchCompaniesInCountry', [
                    listCurArticleCountry.cases
                      ? listCurArticleCountry.cases.prepositional
                      : listCurArticleCountry.name,
                  ])
            "
          ></span
        ></router-link>
      </div>
    </transition>
    <div class="recent-articles-grid" v-if="listCurArticle">
      <transition-group tag="div" class="articles-column" name="tiles-fade">
        <div
          class="article-tile"
          v-for="curItem in items"
          :class="[
            { 'news-tile': curItem.category_id === 0 },
            { 'publications-tile': curItem.category_id === 1 },
          ]"
          :key="curItem.id"
        >
          <img
            v-if="curItem.flag"
            loading="lazy"
            class="flag-icon"
            :src="'//' + serverLink + curItem.flag"
            alt="country"
          />
          <div class="date-and-title">
            <div class="doc-date" v-if="curItem.category_id === 0">
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.99935 1.16666C8.54645 1.16666 10.0302 1.78124 11.1241 2.8752C12.2181 3.96916 12.8327 5.45289 12.8327 6.99999C12.8327 8.54709 12.2181 10.0308 11.1241 11.1248C10.0302 12.2187 8.54645 12.8333 6.99935 12.8333C5.45225 12.8333 3.96852 12.2187 2.87456 11.1248C1.7806 10.0308 1.16602 8.54709 1.16602 6.99999C1.16602 5.45289 1.7806 3.96916 2.87456 2.8752C3.96852 1.78124 5.45225 1.16666 6.99935 1.16666ZM6.41602 4.08332C6.26131 4.08332 6.11293 4.14478 6.00354 4.25418C5.89414 4.36357 5.83268 4.51195 5.83268 4.66666V7.58332C5.83268 7.73803 5.89414 7.88641 6.00354 7.9958C6.11293 8.1052 6.26131 8.16666 6.41602 8.16666H8.74935C8.90406 8.16666 9.05243 8.1052 9.16183 7.9958C9.27122 7.88641 9.33268 7.73803 9.33268 7.58332C9.33268 7.42861 9.27122 7.28024 9.16183 7.17084C9.05243 7.06145 8.90406 6.99999 8.74935 6.99999H6.99935V4.66666C6.99935 4.51195 6.93789 4.36357 6.8285 4.25418C6.7191 4.14478 6.57073 4.08332 6.41602 4.08332Z"
                  fill="currentColor"
                />
              </svg>

              {{ curItem.date }}
            </div>
            <div class="doc-title">{{ curItem.name }}</div>
          </div>
          <router-link :to="curItem.link" class="read-more">
            <span @click="refreshArticle">
              {{ translations("ReadMore") }}
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  y="14"
                  width="14"
                  height="14"
                  rx="3.88889"
                  transform="rotate(-90 0 14)"
                  fill="currentColor"
                />
                <path
                  d="M8.75109 7L6.276 9.47508L5.45117 8.64967L7.10142 7L5.45117 5.35033L6.276 4.52492L8.75109 7Z"
                  fill="white"
                />
              </svg>
            </span>
          </router-link>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<script>
import { VUE_APP_BACK_URL } from "@/config";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Article",
  metaInfo() {
    return {
      title: this.listCurArticle.meta_title + " | " + this.listLang.site_name,
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content: this.listCurArticle.meta_desc,
        },
        {
          name: "keywords",
          content: this.listCurArticle.meta_kwords,
        },
      ],
    };
  },
  data: () => {
    return {
      serverLink: VUE_APP_BACK_URL,
      items: [],
      storedItems: [],
      itemDelay: 100,
    };
  },
  components: {},
  computed: {
    ...mapGetters([
      "listSettings",
      "listLang",
      "translations",
      "listCurArticle",
      "listRecentArticles",
      "listCurArticleCountry",
    ]),
  },
  methods: {
    ...mapActions(["fetchArticle", "clearArticle"]),
    addItem() {
      if (this.storedItems.length) {
        this.items.push(this.storedItems.pop());
      } else {
        clearInterval(this.addingInterval);
      }
    },
    refreshArticle() {
      setTimeout(async () => {
        console.log("refresh");
        this.items = [];
        this.storedItems = [];
        await this.fetchArticle({
          lang: this.$cookies.get("user_language"),
          path: this.$route.path,
        });
        this.storedItems = this.listRecentArticles.slice();
        clearInterval(this.addingInterval);
        this.addingInterval = setInterval(this.addItem, this.itemDelay);
      }, 10);
    },
  },
  async created() {
    this.items = [];
    this.storedItems = [];
    await this.fetchArticle({
      lang: this.$cookies.get("user_language"),
      path: this.$route.path,
    });
    this.storedItems = this.listRecentArticles.slice();
    clearInterval(this.addingInterval);
    this.addingInterval = setInterval(this.addItem, this.itemDelay);
  },
};
</script>